import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function NotFound() {
  const navigate = useNavigate();

  return (
    <Container style={{ textAlign: "center", marginTop: "50px" }}>
      <img
        src="not-found.jpg"
        alt="Not Found"
        style={{ width: "100%", maxWidth: "700px", margin: "20px auto" }}
      />
      <ErrorOutlineIcon
        style={{
          fontSize: 80,
          color: "#9e9e9e",
          display: "block",
          margin: "20px auto",
        }}
      />
      <Typography variant="h4" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" gutterBottom>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/home")}
      >
        Go to Home
      </Button>
    </Container>
  );
}

export default NotFound;
