import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  handleCreateFolder,
  handleFilesDelete,
  handleClearCurrentSelections,
} from "../handlers";
import {
  JAMSIL_FB_NEW_FOLDER,
  JAMSIL_FB_DELETE_SELECTED,
  JAMSIL_FB_DESELECT_FILES,
} from "../../../core/string_table/JamsilFileBrowserStrings";
import getJamsilFbString from "../JamsilFbString";

const fileMenuTheme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "0.8rem",
        },
      },
    },
  },
});

export default function FileMenu({ title = "File Actions" }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="file-menu-button"
        aria-controls={open ? "file-menu-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="file-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ThemeProvider theme={{ ...fileMenuTheme }}>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleCreateFolder();
                handleClose();
              }}
            >
              <ListItemIcon>
                <CreateNewFolderIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {getJamsilFbString(JAMSIL_FB_NEW_FOLDER)}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleFilesDelete();
                handleClose();
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {getJamsilFbString(JAMSIL_FB_DELETE_SELECTED)}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClearCurrentSelections();
                handleClose();
              }}
            >
              <ListItemIcon>
                <LayersClearIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {getJamsilFbString(JAMSIL_FB_DESELECT_FILES)}
              </ListItemText>
            </MenuItem>
          </MenuList>
        </ThemeProvider>
      </Menu>
    </>
  );
}
