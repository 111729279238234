import {
  userRetrieve,
  dashboardPaidMonths,
  dashboardMonthlyCharge,
  getCurrentFolder,
  getFolderTree,
} from "./ApiFns";
import { getGS, setGS } from "usegs";
import {
  USER,
  MONTHLY_CHARGE,
  PAID_MONTHS,
  CURRENT_FOLDER,
  FOLDER_TREE,
} from "./GlobalStateKeys";

export async function GET_USER() {
  const res = await userRetrieve();
  if (res.is_ok) {
    setGS(USER, res.result);
    return res;
  }
  return {
    is_ok: false,
    message: "Failed to retrieve user",
  };
}

export async function GET_MONTHLY_CHARGE(request) {
  if (request === null) {
    return {
      is_ok: false,
      message: "Invalid year and month",
    };
  }
  const res = await dashboardMonthlyCharge(request);
  if (res.is_ok) {
    setGS(MONTHLY_CHARGE, res.result);
    return res;
  }
  return {
    is_ok: false,
    message: "Failed to retrieve monthly charge",
  };
}

export async function GET_PAID_MONTHS() {
  const res = await dashboardPaidMonths();
  if (res.is_ok) {
    setGS(PAID_MONTHS, res.result);
    return res;
  }
  return {
    is_ok: false,
    message: "Failed to retrieve paid months",
  };
}

export async function GET_CURRENT_FOLDER() {
  const res = await getCurrentFolder();
  if (res.is_ok) {
    setGS(CURRENT_FOLDER, res.result);
    return res;
  }
  return {
    is_ok: false,
    message: "Failed to retrieve current folder",
  };
}

export async function GET_FOLDER_TREE() {
  const res = await getFolderTree();
  if (res.is_ok) {
    setGS(FOLDER_TREE, res.result);
    return res;
  }
  return {
    is_ok: false,
    message: "Failed to retrieve folder tree",
  };
}
