import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useGS } from "usegs";
import {
  SHOW_CIRCULAR_PROGRESS_POPUP,
  PROGRESS_POPUP_MESSAGE,
} from "../core/GlobalStateKeys";

function CircularProgressPopup({ handleClose = () => {} }) {
  const [open] = useGS(SHOW_CIRCULAR_PROGRESS_POPUP);
  const [message] = useGS(PROGRESS_POPUP_MESSAGE);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="loading-dialog-title"
      aria-describedby="loading-dialog-description"
    >
      <DialogTitle id="loading-dialog-title">{message}</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <CircularProgress />
          {/* when want to show a message below the spinner
          <DialogContentText id="loading-dialog-description" sx={{ mt: 2 }}>
            test man
          </DialogContentText>*/}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CircularProgressPopup;
