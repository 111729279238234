import { KOREAN } from "./StringTable";

export const INITIAL_STATES = {
  USER: {
    user_id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    time_zone: "",
    language: KOREAN,
    status: "",
  },
  CURRENT_FOLDER: null,
  FOLDER_TREE: {},
  CURRENT_SELECTED_ITEMS: [],
  SHOW_FOLDER_CREATION_DIALOG: false,
  SHOW_CIRCULAR_PROGRESS_DIALOG: false,
  PROGRESS_DIALOG_MESSAGE: "",
  CURRENT_SELECTION: [],
  TRIGGER_FILES_UPLOAD: false,
  TRIGGER_FILES_DOWNLOAD: false,
  ITEM_ORDERING_OPTION: "NAME_ASC",
  SEARCH_KEYWORD: "",
  SHOW_PAYMENT_GUIDE: false,
  MONTHLY_CHARGE: {
    base_charge: "KRW 0",
    total_daily_charge: "KRW 0",
    total_charge: "KRW 0",
    daily_charges: [],
  },
  PAID_MONTHS: null,
  NOTIFICATION_MESSAGE: { show: false, message: "" },
};
