import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { CREATE_FOLDER } from "../core/AppEventHandlers";
import { SHOW_FOLDER_CREATION_POPUP } from "../core/GlobalStateKeys";
import { useGS } from "usegs";

export default function NewFolderDialog() {
  const [open, setOpen] = useGS(SHOW_FOLDER_CREATION_POPUP);
  const [text, setText] = React.useState("");
  return (
    <Dialog open={open}>
      <DialogTitle>New Folder</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Folder Name"
          type="text"
          fullWidth
          variant="standard"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setText("");
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            CREATE_FOLDER(text);
            setText("");
            setOpen(false);
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
