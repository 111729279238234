import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createTheme,
  ThemeProvider,
  Card,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import { useGS } from "usegs";
import Header from "./Header";
import checkUserStates from "./CheckUserStates";
import {
  DASHBOARD_TITLE,
  DASHBOARD_SERVICE_SETTINGS,
  DASHBOARD_CHARGE_LIMIT,
  DASHBOARD_CHARGE_LIMIT_HELPER_TEXT,
  DASHBOARD_DELETE_ALL_DATE,
  DASHBOARD_DELETE_ALL_DATE_HELPER_TEXT,
  DASHBOARD_MONTHLY_CHARGE,
  DASHBOARD_SELECT_MONTH,
  DASHBOARD_DAILY_CHARGING_HISTORY,
  DASHBOARD_TABLE_DATE,
  DASHBOARD_TABLE_HOT_STORAGE_USAGE,
  DASHBOARD_TABLE_COLD_STORAGE_USAGE,
  DASHBOARD_TABLE_DAILY_CHARGE,
  DASHBOARD_CURRENT_CHARGE,
  DASHBOARD_BASE_CHARGE,
  DASHBOARD_TOTAL_DAILY_CHARGE,
  DASHBOARD_TOTAL_CHARGE,
} from "../core/string_table/DashboardStrings";
import { COMMON_SAVE, COMMON_CANCEL } from "../core/string_table/CommonStrings";
import {
  DASHBOARD_PAGE,
  getString,
  getCommonString,
} from "../core/StringTable";
import { GET_PAID_MONTHS, GET_MONTHLY_CHARGE } from "../core/ServerStateToGs";
import { SET_DATA_DELETION_DATE } from "../core/AppEventHandlers";
import {
  convertIsoDatetimeToYearMonthDay,
  convertMoneyStringToFloat,
} from "../core/utils";
import { MONTHLY_CHARGE, PAID_MONTHS } from "../core/GlobalStateKeys";

const getDashboardString = (key) => getString(DASHBOARD_PAGE, key);

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#0d47a1",
          color: "#ffffff",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#0d47a1",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#0d47a1",
        },
      },
    },
  },
});

export default function Dashboard() {
  const navigate = useNavigate();
  // const [chargeLimit, setChargeLimit] = useState("");
  const [deleteDate, setDeleteDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [paidMonths] = useGS(PAID_MONTHS);
  const [monthlyCharge] = useGS(MONTHLY_CHARGE);

  useEffect(() => {
    checkUserStates(navigate, "dashboard");
    if (paidMonths === null) {
      GET_PAID_MONTHS();
    }
  }, [navigate, paidMonths]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    const generateRequest = (selectedYearMonth) => {
      const yearMonth = selectedYearMonth.split(".");
      if (yearMonth.length !== 2) {
        return null;
      }
      return {
        year: parseInt(yearMonth[0]),
        month: parseInt(yearMonth[1]),
      };
    };
    const request = generateRequest(event.target.value);
    GET_MONTHLY_CHARGE(request);
  };

  const handleSaveDataDeletionDate = () => {
    SET_DATA_DELETION_DATE({ data_deletion_date: deleteDate });
  };

  const handleCandelDataDeletionDate = () => {
    setDeleteDate("");
    SET_DATA_DELETION_DATE({ data_deletion_date: "" });
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container>
        <br />
        <Typography variant="h4" component="h1" gutterBottom>
          {getDashboardString(DASHBOARD_TITLE)}
        </Typography>

        {/* Charge Limit and Usage Period */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_SERVICE_SETTINGS)}
              </Typography>
              {/* <FormControl fullWidth margin="normal">
                <TextField
                  label={getDashboardString(DASHBOARD_CHARGE_LIMIT)}
                  value={chargeLimit}
                  onChange={(e) => setChargeLimit(e.target.value)}
                  type="number"
                  helperText={getDashboardString(
                    DASHBOARD_CHARGE_LIMIT_HELPER_TEXT
                  )}
                />
              </FormControl> */}
              <FormControl fullWidth margin="normal">
                <TextField
                  label={getDashboardString(DASHBOARD_DELETE_ALL_DATE)}
                  type="date"
                  value={deleteDate}
                  onChange={(e) => setDeleteDate(e.target.value)}
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                  helperText={getDashboardString(
                    DASHBOARD_DELETE_ALL_DATE_HELPER_TEXT
                  )}
                />
              </FormControl>
            </CardContent>
            <Box display="flex" justifyContent="center" m={2} gap={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveDataDeletionDate}
              >
                {getCommonString(COMMON_SAVE)}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={handleCandelDataDeletionDate}
              >
                {getCommonString(COMMON_CANCEL)}
              </Button>
            </Box>
          </Card>
        </Box>

        {/* Monthly Charge */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_MONTHLY_CHARGE)}
              </Typography>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="month-select-label">
                  {getDashboardString(DASHBOARD_SELECT_MONTH)}
                </InputLabel>
                <Select
                  labelId="month-select-label"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  label={getDashboardString(DASHBOARD_SELECT_MONTH)}
                >
                  {paidMonths &&
                    paidMonths.map((yearMonth) => (
                      <MenuItem key={yearMonth} value={yearMonth}>
                        {yearMonth}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </CardContent>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_DAILY_CHARGING_HISTORY)}
              </Typography>
              {monthlyCharge.daily_charges ? (
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: 380, overflow: "auto" }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {getDashboardString(DASHBOARD_TABLE_DATE)}
                        </TableCell>
                        <TableCell align="right">
                          {getDashboardString(
                            DASHBOARD_TABLE_HOT_STORAGE_USAGE
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {getDashboardString(
                            DASHBOARD_TABLE_COLD_STORAGE_USAGE
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {getDashboardString(DASHBOARD_TABLE_DAILY_CHARGE)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {monthlyCharge.daily_charges.map((day, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {convertIsoDatetimeToYearMonthDay(day.date)}
                          </TableCell>
                          <TableCell align="right">
                            {day.hot_file_size_in_gb}
                          </TableCell>
                          <TableCell align="right">
                            {day.cold_file_size_in_gb}
                          </TableCell>
                          <TableCell align="right">
                            {(day.hot_charge + day.cold_charge).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>Loading storage data...</Typography>
              )}
            </CardContent>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_CURRENT_CHARGE)}
              </Typography>
              <Typography>
                {getDashboardString(DASHBOARD_BASE_CHARGE)}: ₩{" "}
                {convertMoneyStringToFloat(monthlyCharge.base_charge).toFixed(
                  2
                )}
                {/* {baseCharge.toFixed(2)} */}
              </Typography>
              <Typography>
                {getDashboardString(DASHBOARD_TOTAL_DAILY_CHARGE)}: ₩{" "}
                {convertMoneyStringToFloat(
                  monthlyCharge.total_daily_charge
                ).toFixed(2)}
                {/* {totalDailyCharge.toFixed(2)} */}
              </Typography>
              <br />
              <Typography variant="h5" component="h3">
                {getDashboardString(DASHBOARD_TOTAL_CHARGE)}: ₩{" "}
                {convertMoneyStringToFloat(monthlyCharge.total_charge).toFixed(
                  2
                )}
                {/* {totalCharge.toFixed(2)} */}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
