import React from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
import ItemView from "./ItemView";
import Box from "@mui/material/Box";
import FileToolBar from "./FileToolBar";
import FolderNavigator from "./filetoolbar/FolderNavigator";
import { CURRENT_FOLDER } from "../../core/GlobalStateKeys";
import { useGS } from "usegs";

export default function FileBrowser({ height }) {
  const [showFileNav, setShowFileNav] = React.useState(false);
  const [currentFolder] = useGS(CURRENT_FOLDER);
  const isMediumOrLarger = useMediaQuery(createTheme().breakpoints.up("md"));
  const fileToolBarMinWidth = "600px";

  var items = [];
  if (currentFolder) {
    items = Object.values(currentFolder.items);
  }

  return (
    <Stack sx={{ height: height, overflow: "auto" }}>
      <Box sx={{ width: "100%" }}>
        <FileToolBar
          minWidth={fileToolBarMinWidth}
          toggleFileNav={setShowFileNav}
        />
      </Box>
      <Divider />
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Stack direction="row" sx={{ height: "100%", width: "100%" }}>
          {isMediumOrLarger ? (
            showFileNav ? (
              <>
                <Box sx={{ width: "33.33vw", height: "100%" }}>
                  <FolderNavigator />
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ width: "66.67vw", height: "100%" }}>
                  <ItemView items={items} />
                </Box>
              </>
            ) : (
              <Box sx={{ width: "100%", height: "100%" }}>
                <ItemView items={items} />
              </Box>
            )
          ) : showFileNav ? (
            <Box sx={{ width: "100%", height: "100%" }}>
              <FolderNavigator />
            </Box>
          ) : (
            <Box sx={{ width: "100%", height: "100%" }}>
              <ItemView items={items} />
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
