import React from "react";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { initGS } from "usegs";
import Home from "./app_components/Home";
import Dashboard from "./app_components/Dashboard";
import SignIn from "./app_components/SignIn";
import SignUp from "./app_components/SignUp";
import EmailVerification from "./app_components/EmailVerification";
import ServiceDetails from "./app_components/ServiceDetails";
import Checkout from "./app_components/Checkout";
import Account from "./app_components/Account";
import CardRegistrationSuccess from "./app_components/CardRegistrationSuccess";
import CardRegistrationFail from "./app_components/CardRegistrationFail";
import {
  handleReload,
  handleFlutterEvent,
} from "./app_components/jamsil_filebrowser/handlers";
import NotFound from "./app_components/NotFound";
import PromotionCampaign from "./app_components/PromotionCampaign";
import { INITIAL_STATES } from "./core/InitialStates";

initGS(INITIAL_STATES);

function App() {
  React.useEffect(() => {
    window.addEventListener("load", handleReload);
    window.addEventListener("flutterEvent", handleFlutterEvent);
    return () => {
      window.removeEventListener("load", handleReload);
      window.removeEventListener("flutterEvent", handleFlutterEvent);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path="/email-verification/:verificationKey"
          element={<EmailVerification />}
        />
        <Route path="/home" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/details" element={<ServiceDetails />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/account" element={<Account />} />
        <Route path="/campaign/:campaignId" element={<PromotionCampaign />} />
        <Route
          path="/card-registration-success"
          element={<CardRegistrationSuccess />}
        />
        <Route
          path="/card-registration-fail"
          element={<CardRegistrationFail />}
        />
        <Route path="/health" element={<div>ok</div>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
