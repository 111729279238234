import React from "react";
import { TRIGGER_FILES_DOWNLOAD } from "../../core/GlobalStateKeys";
import { useGS } from "usegs";
import { handleFileDownloads } from "./handlers";

export default function FileDownload() {
  const [triggerDownload, setTriggerDownload] = useGS(TRIGGER_FILES_DOWNLOAD);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (triggerDownload && inputRef.current) {
      inputRef.current.click();
      setTriggerDownload(false);
    }
  }, [triggerDownload, setTriggerDownload]);

  return (
    <div
      style={{ display: "none" }}
      ref={inputRef}
      onClick={handleFileDownloads}
    />
  );
}
