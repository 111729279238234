import { getGS, setGS } from "usegs";
import {
  SHOW_FOLDER_CREATION_POPUP,
  CURRENT_FOLDER,
  FOLDER_TREE,
  CURRENT_SELECTED_ITEMS,
  TRIGGER_FILES_UPLOAD,
  TRIGGER_FILES_DOWNLOAD,
  SHOW_CIRCULAR_PROGRESS_POPUP,
  PROGRESS_POPUP_MESSAGE,
} from "../../core/GlobalStateKeys";
import {
  SET_CURRENT_FOLDER,
  CREATE_FILE,
  DELETE_FILE,
  DELETE_FOLDER,
  MAKE_DOWNLOAD_LINKS,
} from "../../core/AppEventHandlers";
import {
  GET_CURRENT_FOLDER,
  GET_FOLDER_TREE,
} from "../../core/ServerStateToGs";
import { getPlatform, Platform } from "../../core/utils";
import {
  flutterDownloadFiles,
  flutterOpenFile,
} from "../../core/FlutterBridges";

export function handleReload() {
  console.log("handleReload");
  GET_CURRENT_FOLDER();
}

function getMimeType(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  const mimeTypes = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // Add more mappings as needed
  };
  return mimeTypes[extension] || "application/octet-stream";
}

function base64ToBlob(base64, mime) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mime });
}

export function handleFlutterEvent(event) {
  if (event.detail.type === "previousFolder") {
    const folderId = event.detail.folderId;
    console.log("handleFlutterEvent folderId: ", folderId);
    SET_CURRENT_FOLDER(folderId);
  } else if (event.detail.type == "fileUpload") {
    try {
      console.log("processing fileUpload event");
      var flutterFiles = JSON.parse(event.detail.fileList);
      console.log(
        "uploading file names: " + flutterFiles.map((file) => file.name)
      );
      const files = flutterFiles.map((file) => {
        const mimeType = getMimeType(file.name);
        const blob = base64ToBlob(file.file, mimeType);
        return new File([blob], file.name, { type: mimeType });
      });
      handleFileUploads(files);
    } catch (error) {
      console.error("handleFlutterEvent fileUpload error: ", error);
    }
  } else if (event.detail.type == "filesDownloadComplete") {
    console.log("filesDownloadComplete");
    postFileDownloads();
  }
}

export function handleCreateFolder() {
  setGS(SHOW_FOLDER_CREATION_POPUP, true);
}

export async function handleDoubleClick(item) {
  if (item.item_type === "FOLDER") {
    // console.log("change folder to ", item.item_id);
    await SET_CURRENT_FOLDER(item.item_id);
    setGS(CURRENT_SELECTED_ITEMS, []);
  } else if (item.item_type === "FILE") {
    const current_folder = getGS(CURRENT_FOLDER);
    var folder_tree = getGS(FOLDER_TREE);
    if (!current_folder || !folder_tree) {
      console.error("failed to get current_folder or folder_tree");
      return;
    }
    if (!folder_tree["folder_tree"]) {
      await GET_FOLDER_TREE();
      folder_tree = getGS(FOLDER_TREE);
    }
    // console.log(
    //   "open file ",
    //   item.item_id,
    //   " in folder ",
    //   current_folder.folder_id
    // );
    const folderNamePath = getFolderNamePath(
      current_folder.folder_id,
      folder_tree["folder_tree"]
    );
    flutterOpenFile(item, folderNamePath);
  }
}

// {"folder_id": folder.folder_id, "name": folder.name, "children": []}
const getFolderNamePath = (folderId, treeItem) => {
  if (!treeItem) {
    return null;
  }
  if (folderId === treeItem.folder_id) {
    return [treeItem.name];
  }
  if (treeItem.children) {
    for (const child of treeItem.children) {
      const result = getFolderNamePath(folderId, child);
      if (result) {
        return [treeItem.name, ...result];
      }
    }
  }
  return null;
};

export function handleGoingUp() {
  const currentFolder = getGS(CURRENT_FOLDER);
  if (currentFolder.parent_id === 0) {
    return;
  }
  SET_CURRENT_FOLDER(currentFolder.parent_id);
  setGS(CURRENT_SELECTED_ITEMS, []);
}

export async function handleFileUploads(files) {
  const currentFolder = getGS(CURRENT_FOLDER);
  setGS(SHOW_CIRCULAR_PROGRESS_POPUP, true);
  setGS(PROGRESS_POPUP_MESSAGE, "Uploading files...");
  for (const file of files) {
    await CREATE_FILE(file, currentFolder);
  }
  setGS(SHOW_CIRCULAR_PROGRESS_POPUP, false);
  GET_CURRENT_FOLDER();
}

export function triggetFilesUpload() {
  setGS(TRIGGER_FILES_UPLOAD, true);
}

export function triggerFilesDownload() {
  setGS(TRIGGER_FILES_DOWNLOAD, true);
}

const getFileItem = (fileId, selectedItems) => {
  const fileIdNum = parseInt(fileId);
  for (const item of selectedItems) {
    console.log(item);
    if (item.item_id === fileIdNum && item.item_type === "FILE") {
      return item;
    }
  }
  return null;
};

export async function handleFileDownloads() {
  const selectedItems = getGS(CURRENT_SELECTED_ITEMS);
  const downloadLinks = await MAKE_DOWNLOAD_LINKS(selectedItems);
  const linksAndNames = {};
  for (let [fileId, fileUrl] of Object.entries(downloadLinks)) {
    const fileItem = getFileItem(fileId, selectedItems);
    if (fileItem === null) {
      console.error("failed to find fileItem by id ", fileId);
      continue;
    }
    const newFileName = fileItem.name;
    // { fileId: { url: fileUrl, name: newFileName } }
    linksAndNames[fileId] = { url: fileUrl, name: newFileName };
  }
  console.log("showing downloading popup...");
  setGS(SHOW_CIRCULAR_PROGRESS_POPUP, true);
  setGS(PROGRESS_POPUP_MESSAGE, "Downloading files...");
  const platform = getPlatform();
  if (platform === Platform.ANDROID || platform === Platform.IOS) {
    flutterDownloadFiles(linksAndNames);
  } else {
    for (let [fileId, { url, name }] of Object.entries(linksAndNames)) {
      await downloadFile(url, name);
    }
    postFileDownloads();
  }
}

const downloadFile = async (fileUrl, newFileName) => {
  try {
    const response = await fetch(fileUrl, { method: "GET" });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = newFileName;
    document.body.appendChild(a);
    a.click();
    console.log("clicked the anchor to download a file");
    document.body.removeChild(a);
  } catch (error) {
    console.error(`Failed to download file: ${error}`);
  }
};

export async function postFileDownloads() {
  setGS(CURRENT_SELECTED_ITEMS, []);
  setGS(SHOW_CIRCULAR_PROGRESS_POPUP, false);
  setGS(PROGRESS_POPUP_MESSAGE, "");
}

export async function handleFilesDelete() {
  const selectedItems = getGS(CURRENT_SELECTED_ITEMS);
  for (const item of selectedItems) {
    if (item.item_type === "FOLDER") {
      await DELETE_FOLDER(item.item_id);
      GET_FOLDER_TREE();
    } else {
      await DELETE_FILE(item.item_id);
    }
  }
  setGS(CURRENT_SELECTED_ITEMS, []);
  GET_CURRENT_FOLDER();
}

export function handleClearCurrentSelections() {
  setGS(CURRENT_SELECTED_ITEMS, []);
}
