import { KOREAN, ENGLISH } from "../StringTable";

export const JAMSIL_FB_NEW_FOLDER = "JAMSIL_FB_NEW_FOLDER";
export const JAMSIL_FB_DELETE_SELECTED = "JAMSIL_FB_DELETE_SELECTED";
export const JAMSIL_FB_DESELECT_FILES = "JAMSIL_FB_DESELECT_FILES";
export const JAMSIL_FB_FILE_SORT_ASC = "JAMSIL_FB_FILE_SORT_ASC";
export const JAMSIL_FB_FILE_SORT_DESC = "JAMSIL_FB_FILE_SORT_DESC";
export const JAMSIL_FB_FOLDER_EXPLORER = "JAMSIL_FB_FOLDER_EXPLORER";
export const JAMSIL_FB_PARENT_FOLDER = "JAMSIL_FB_PARENT_FOLDER";
export const JAMSIL_FB_FILE_UPLOAD = "JAMSIL_FB_FILE_UPLOAD";
export const JAMSIL_FB_FILE_DOWNLOAD = "JAMSIL_FB_FILE_DOWNLOAD";
export const JAMSIL_FB_FILE = "JAMSIL_FB_FILE";
export const JAMSIL_FB_SORT_OPTION = "JAMSIL_FB_SORT_OPTION";
export const JAMSIL_FB_SEARCH = "JAMSIL_FB_SEARCH";

export const JAMSIL_FB_STRINGS = {
  JAMSIL_FB_NEW_FOLDER: {
    KOREAN: "새 폴더",
    ENGLISH: "New Folder",
  },
  JAMSIL_FB_DELETE_SELECTED: {
    KOREAN: "선택 삭제",
    ENGLISH: "Delete Selected",
  },
  JAMSIL_FB_DESELECT_FILES: {
    KOREAN: "선택 해제",
    ENGLISH: "Deselect Files",
  },
  JAMSIL_FB_FILE_SORT_ASC: {
    KOREAN: "파일명 오름차순",
    ENGLISH: "File Name Ascending",
  },
  JAMSIL_FB_FILE_SORT_DESC: {
    KOREAN: "파일명 내림차순",
    ENGLISH: "File Name Descending",
  },
  JAMSIL_FB_FOLDER_EXPLORER: {
    KOREAN: "폴더 탐색기",
    ENGLISH: "Folder Explorer",
  },
  JAMSIL_FB_PARENT_FOLDER: {
    KOREAN: "상위 폴더",
    ENGLISH: "Parent Folder",
  },
  JAMSIL_FB_FILE_UPLOAD: {
    KOREAN: "파일 업로드",
    ENGLISH: "File Upload",
  },
  JAMSIL_FB_FILE_DOWNLOAD: {
    KOREAN: "파일 다운로드",
    ENGLISH: "File Download",
  },
  JAMSIL_FB_FILE: {
    KOREAN: "파일",
    ENGLISH: "File",
  },
  JAMSIL_FB_SORT_OPTION: {
    KOREAN: "정렬 옵션",
    ENGLISH: "Sort Option",
  },
  JAMSIL_FB_SEARCH: {
    KOREAN: "찾기",
    ENGLISH: "Search",
  },
};
