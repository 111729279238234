import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGS } from "usegs";
import { CURRENT_FOLDER } from "../core/GlobalStateKeys";
import Header from "./Header";
import NewFolderDialog from "./NewFolderDialog";
import FileBrowser from "./jamsil_filebrowser/FileBrowser";
import FileUpload from "./FileUpload";
import FileDownload from "./jamsil_filebrowser/FileDownload";
import checkUserStates from "./CheckUserStates";
import PaymentGuide from "./PaymentGuide";
import NotificationSnackbar from "./NotificationSnackbar";
import CircularProgressPopup from "./CircularProgressPopup";
import { getPlatform, Platform } from "../core/utils";
import { CALL_FLUTER_MOVE_TO_ANOTHER_FOLDER } from "../core/AppEventHandlers";
import { GET_USER } from "../core/ServerStateToGs";

// Import MUI components and icons
import { Box, Typography, Button } from "@mui/material";
import MobileOffIcon from "@mui/icons-material/MobileOff";

export default function Home() {
  const navigate = useNavigate();
  const [currentFolder] = useGS(CURRENT_FOLDER);

  const platform = getPlatform();

  useEffect(() => {
    GET_USER();
  }, []);

  React.useEffect(() => {
    checkUserStates(navigate, "home");

    if (platform === Platform.ANDROID || platform === Platform.IOS) {
      if (currentFolder) {
        CALL_FLUTER_MOVE_TO_ANOTHER_FOLDER(currentFolder.folder_id);
      }
    }
  }, [navigate, currentFolder, platform]);

  if (!currentFolder) {
    return <div></div>;
  }

  return (
    <>
      {platform !== Platform.MOBILE_WEB && (
        <>
          <Header />
          <FileBrowser height="100vh" />
          <NewFolderDialog />
          <FileUpload />
          <FileDownload />
          <PaymentGuide />
          <NotificationSnackbar />
          <CircularProgressPopup />
        </>
      )}
      {platform === Platform.MOBILE_WEB && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          bgcolor="#f5f5f5"
          p={2}
        >
          <MobileOffIcon style={{ fontSize: 80, color: "#9e9e9e" }} />
          <Typography variant="h6" align="center" gutterBottom>
            모바일 브라우저는 지원하지 않습니다.
          </Typography>
          <Typography variant="subtitle1" align="center">
            App을 설치해 주세요. (현재는 Android만 지원하고 있습니다. 곧 iOS
            지원 예정 입니다.)
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://s3.ap-northeast-2.amazonaws.com/public.cloudusb.io/apk/cloudusb.io.apk"
            sx={{ mt: 2 }}
          >
            App 설치하기
          </Button>
        </Box>
      )}
    </>
  );
}
