import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SortIcon from "@mui/icons-material/Sort";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ITEM_ORDERING_OPTION } from "../../../core/GlobalStateKeys";
import {
  JAMSIL_FB_FILE_SORT_ASC,
  JAMSIL_FB_FILE_SORT_DESC,
} from "../../../core/string_table/JamsilFileBrowserStrings";
import getJamsilFbString from "../JamsilFbString";
import { useGS } from "usegs";

const fileSortOptionMenuTheme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "0.8rem",
        },
      },
    },
  },
});

export default function FileSortOptionMenu({ title = "View Options" }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [itemOrderingOption, setItemOrderingOption] =
    useGS(ITEM_ORDERING_OPTION);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="item-view-option-menu-button"
        aria-controls={open ? "item-view-option-menu-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="item-view-option-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ThemeProvider theme={fileSortOptionMenuTheme}>
          <MenuList>
            <MenuItem
              onClick={() => {
                if (itemOrderingOption === "NAME_ASC") {
                  setItemOrderingOption("NAME_DESC");
                } else {
                  setItemOrderingOption("NAME_ASC");
                }
              }}
            >
              <ListItemIcon>
                {itemOrderingOption === "NAME_ASC" ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )}
                <SortIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {itemOrderingOption === "NAME_ASC"
                  ? getJamsilFbString(JAMSIL_FB_FILE_SORT_ASC)
                  : getJamsilFbString(JAMSIL_FB_FILE_SORT_DESC)}
              </ListItemText>
            </MenuItem>
          </MenuList>
        </ThemeProvider>
      </Menu>
    </>
  );
}
