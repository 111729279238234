import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";

import CloudusbMainLogo from "../components/CloudusbMainLogo";
import {
  ISSUE_BILLING_KEY,
  CREATE_FIRST_MONTHLY_ORDER,
  PAY_ORDER,
  GET_USER_STATES,
  CHECK_USER_PROMOTION_COVERING_PAYMENT,
} from "../core/AppEventHandlers";

const CARD_REGISTRATION_SUCCESS = 0;
const PROCESSING_BASE_RATE_PAYMENT = 1;
const BASE_RATE_ORDER_CREATION = 2;
const BASE_RATE_PAYMENT_SUCCESS = 3;
const BASE_RATE_PAYMENT_FAIL = 4;
const PAYMENT_FAILURE = 5;

export default function CardRegistrationSuccess() {
  const [state, setState] = React.useState(CARD_REGISTRATION_SUCCESS);
  const [orderNo, setOrderNo] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const customerKey = queryParams.get("customerKey");
  const authKey = queryParams.get("authKey");

  const headerTitle = (() => {
    switch (state) {
      case CARD_REGISTRATION_SUCCESS:
        if (!customerKey || !authKey) {
          return "프로모션 코드 확인 중";
        } else {
          return "카드 등록 성공";
        }
      case BASE_RATE_ORDER_CREATION:
      case PROCESSING_BASE_RATE_PAYMENT:
        return "기본 요금 결제 진행 중";
      case BASE_RATE_PAYMENT_SUCCESS:
        return "기본 요금 결제 성공";
      case BASE_RATE_PAYMENT_FAIL:
        return "기본 요금 결제 실패";
      default:
        return "내부 오류";
    }
  })();

  const contentText = (() => {
    switch (state) {
      case CARD_REGISTRATION_SUCCESS:
        if (!customerKey || !authKey) {
          return "등록된 프로모션 코드를 확인합니다.";
        } else {
          return "카드 등록에 성공했습니다. 기본 요금 결제를 진행합니다.";
        }
      case BASE_RATE_ORDER_CREATION:
      case PROCESSING_BASE_RATE_PAYMENT:
        return "기본 요금 결제를 진행 중입니다. 잠시만 기다려 주세요.";
      case BASE_RATE_PAYMENT_SUCCESS:
        return "기본 요금 결제에 성공했습니다. 이제 클라우드 스토리지를 자유롭게 사용하실 수 있습니다.";
      case BASE_RATE_PAYMENT_FAIL:
        return "기본 요금 결제에 실패했습니다. 다시 시도해 주세요.";
      default:
        return "내부 오류가 발생했습니다. 다시 시도해 주세요.";
    }
  })();

  React.useEffect(() => {
    if (state === CARD_REGISTRATION_SUCCESS) {
      if (!customerKey || !authKey) {
        CHECK_USER_PROMOTION_COVERING_PAYMENT().then((res) => {
          if (res.is_ok && res.result.is_payment_covered) {
            setState(BASE_RATE_ORDER_CREATION);
          } else {
            setState(PAYMENT_FAILURE);
          }
        });
      } else {
        ISSUE_BILLING_KEY({
          customer_key: customerKey,
          auth_key: authKey,
        }).then((res) => {
          if (res.is_ok) {
            setState(BASE_RATE_ORDER_CREATION);
          } else {
            setState(PAYMENT_FAILURE);
          }
        });
      }
    } else if (state === BASE_RATE_ORDER_CREATION) {
      CREATE_FIRST_MONTHLY_ORDER().then((res) => {
        if (res.is_ok) {
          setOrderNo(res.result.order_no);
          setState(PROCESSING_BASE_RATE_PAYMENT);
        } else {
          setState(BASE_RATE_PAYMENT_FAIL);
        }
      });
    } else if (state === PROCESSING_BASE_RATE_PAYMENT) {
      PAY_ORDER({ order_no: orderNo }).then((res) => {
        if (res.is_ok) {
          if (res.result.status === "SUCCESS") {
            setState(BASE_RATE_PAYMENT_SUCCESS);
            GET_USER_STATES();
          } else {
            setState(BASE_RATE_PAYMENT_FAIL);
          }
        } else {
          setState(BASE_RATE_PAYMENT_FAIL);
        }
      });
    }
  }, [state, setState, customerKey, authKey, orderNo, setOrderNo]);

  return (
    <div style={{ backgroundColor: "#ebf9cf", height: "100vh" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "left",
          pt: 1,
        }}
      >
        <CloudusbMainLogo sx={{ width: "20%", maxWidth: 130 }} />
      </Box>
      <Container component="main" sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: "sm",
              backgroundColor: "lightblue",
            }}
          >
            <CardHeader title={headerTitle} />
            <CardContent sx={{ backgroundColor: "white" }}>
              <Stack direction="row" sx={{ my: 1 }}>
                <CheckIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  {contentText}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            sx={{ width: "100%", maxWidth: "sm", my: 1 }}
            onClick={() => navigate("/home")}
          >
            홈으로 돌아가기
          </Button>
        </Box>
      </Container>
    </div>
  );
}
