import { UAParser } from "@ua-parser-js/pro-business";

export function isNotBlank(str) {
  return typeof str === "string" && str.trim().length > 0;
}

export function isBlank(str) {
  return str == null || str === undefined || !isNotBlank(str);
}

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + encodeURIComponent(value) + expires + "; path=/";
}

export const isValidValue = (obj, ...keys) => {
  if (!obj) {
    return false;
  }
  let innerObj = obj;
  for (let i = 0; i < keys.length; i++) {
    if (!innerObj[keys[i]]) {
      return false;
    }
    innerObj = innerObj[keys[i]];
  }
  return true;
};

export function generateRandomString(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRunningZone() {
  var zone = process.env.REACT_APP_RUNNING_ZONE;
  if (zone === null || zone === undefined || zone === "") {
    return "LOCAL";
  }
  return zone;
}

export function getDomainName() {
  var zone = getRunningZone();
  if (zone === "LOCAL") {
    return "http://localhost:3000";
  }
  return "https://cloudusb.io";
}

export async function retryApiFn(fn, interval, maxRetries) {
  let retries = 0;
  while (retries < maxRetries) {
    const response = await fn();
    console.log("retryApiFn response: ", response);
    if (response.is_ok) {
      return response;
    }
    retries++;
    await new Promise((resolve) => setTimeout(resolve, interval));
  }
  return { is_ok: false, message: "Max retries " + maxRetries + " exceeded" };
}

export const Platform = {
  ANDROID: "ANDROID",
  IOS: "IOS",
  MOBILE_WEB: "MOBILE_WEB",
  WEB: "WEB",
};

let _parser = null;

export function getPlatform() {
  if (_parser === null || _parser === undefined) {
    _parser = new UAParser(navigator.userAgent);
  }
  // console.log("navigator.userAgent: ", navigator.userAgent);
  // console.log("parser result: ", JSON.stringify(_parser.getResult()));

  const device = _parser.getDevice();
  const os = _parser.getOS();
  const browser = _parser.getBrowser();

  // console.log("device: ", device);
  // console.log("os: ", os);
  // console.log("browser: ", browser);

  if (device.is("mobile")) {
    if (os.is("Android")) {
      if (browser.name.includes("WebView")) {
        return Platform.ANDROID;
      }
      return Platform.MOBILE_WEB;
    } else if (os.is("iOS")) {
      if (browser.name.includes("WebView")) {
        return Platform.IOS;
      }
      return Platform.MOBILE_WEB;
    }
  }

  return Platform.WEB;
}

export function createEnum(values) {
  const enumObject = {};
  for (const val of values) {
    enumObject[val] = val;
  }
  return Object.freeze(enumObject);
}

export function extractErrorCode(errorString) {
  if (!errorString || typeof errorString !== "string") {
    return null;
  }
  const match = errorString.match(/\[error_code:\s*(\w+)\]/);
  if (match && match.length > 1) {
    return match[1]; // The captured error code
  } else {
    return null; // No error code found
  }
}

export function getFolderTreeRoot(folderTree) {
  if (
    !folderTree ||
    "folder_tree" in folderTree === false ||
    !folderTree["folder_tree"]
  ) {
    return null;
  }
  return folderTree["folder_tree"];
}

export function getCurrentFolderId(currentFolder) {
  if (!currentFolder || "folder_id" in currentFolder === false) {
    return null;
  }
  return currentFolder["folder_id"];
}

export function hasChildren(node) {
  return node && node.children && node.children.length > 0;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function convertIsoDatetimeToYearMonthDay(isoDateString) {
  const date = new Date(isoDateString);
  if (isNaN(date)) {
    throw new Error("Invalid date string");
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function convertMoneyStringToFloat(moneyString) {
  // Use a regular expression to remove non-numeric characters except for the decimal point
  const numericPart = moneyString.replace(/[^\d.]/g, "");

  // Convert the extracted numeric part to a float
  const floatValue = parseFloat(numericPart);

  // Check if the conversion is successful
  if (isNaN(floatValue)) {
    throw new Error("Invalid money string");
  }

  return floatValue;
}
