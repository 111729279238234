import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import {
  GET_PROMOTION_CAMPAIGN,
  PUBLISH_PROMOTION_CODE,
} from "../core/AppEventHandlers";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const PromotionCampaign = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [email, setEmail] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [promotionStatus, setPromotionStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [apkLink, setApkLink] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  // Fetch promotion data based on promotionId
  useEffect(() => {
    GET_PROMOTION_CAMPAIGN(campaignId).then((res) => {
      if (res.is_ok) {
        const promotionData = res.result;
        const promotionStatus = promotionData.status;
        setPromotionStatus(promotionStatus);
        if (promotionStatus !== "ACTIVE") {
          setImageLink(promotionData.campaign.closed_background_image);
        } else {
          setImageLink(promotionData.campaign.opened_background_image);
        }
        setApkLink(promotionData.campaign.apk || "");
        setLoading(false);
      } else {
        navigate("/not-existing-page");
      }
    });
  }, [campaignId, navigate, setImageLink, setPromotionStatus, setLoading]);

  const handleHomeRedirect = () => {
    navigate("/home");
  };

  const handleSendPromotion = () => {
    if (validateEmail(email)) {
      PUBLISH_PROMOTION_CODE({ email, campaign_id: campaignId }).then((res) => {
        console.log(res);
        if (!res.is_ok) {
          setOpenDialog(true);
        } else {
          navigate("/home");
        }
      });
    } else {
      alert("이메일 주소를 확인해주세요.");
    }
  };

  const validateEmail = (email) => {
    // Simple email validation pattern
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate("/home");
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="#EBF9CF"
      mx="auto"
      p={2}
    >
      <img
        src={imageLink}
        alt="Promotion"
        style={{ maxWidth: "100%", margin: "0" }}
      />

      {apkLink && (
        <Box mt={2}>
          <a href={apkLink} target="_blank" rel="noopener noreferrer">
            Android: APK 다운로드
          </a>
        </Box>
      )}

      {promotionStatus !== "ACTIVE" ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleHomeRedirect}
          sx={{ mt: 2, mb: 4 }}
        >
          Go to Home
        </Button>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={2}
          mb={8}
        >
          <TextField
            label="이메일을 입력 하세요"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mt: 2, mb: 2, width: "300px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendPromotion}
          >
            프로모션 코드 받기
          </Button>
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            프로모션 코드를 보내지 못했습니다. 프로모션을 확인해 주세요.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PromotionCampaign;
