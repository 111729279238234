import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CloudusbMainLogo from "../components/CloudusbMainLogo";
import { styled } from "@mui/system";
import BaseTable from "../components/BaseTable";
import { SERVICE_DETAILS, getString } from "../core/StringTable";
import {
  SERVICE_DETAILS_TO_HOME,
  SERVICE_DETAILS_REGISTER_PAYMENT_METHOD,
  SERVICE_DETAILS_1_TITLE,
  SERVICE_DETAILS_1_HEADER_TEXT,
  SERVICE_DETAILS_1_EXPLANATION,
  SERVICE_DETAILS_2_TITLE,
  SERVICE_DETAILS_2_HEADER_TEXT,
  SERVICE_DETAILS_2_EXPLANATION,
  SERVICE_DETAILS_3_TITLE,
  SERVICE_DETAILS_3_HEADER_TEXT,
  SERVICE_DETAILS_3_EXPLANATION,
  SERVICE_DETAILS_4_TITLE,
  SERVICE_DETAILS_4_HEADER_TEXT,
  SERVICE_DETAILS_4_EXPLANATION,
  SERVICE_DETAILS_5_TITLE,
  SERVICE_DETAILS_5_HEADER_TEXT,
  SERVICE_DETAILS_5_EXPLANATION,
  SERVICE_DETAILS_SCENARIOS_TITLE,
  SERVICE_DETAILS_SCENARIOS_HEADER_TEXT,
  SRVICE_DETAILS_SCENARIO_1_TITLE,
  SERVICE_DETAILS_SCENARIO_1_EXPLANATION,
  SERVICE_DETAILS_SCENARIO_2_TITLE,
  SERVICE_DETAILS_SCENARIO_2_EXPLANATION,
  SERVICE_DETAILS_SCENARIO_3_TITLE,
  SERVICE_DETAILS_SCENARIO_3_EXPLANATION,
  SERVICE_DETAILS_SCENARIO_1ST_MONTH,
  SERVICE_DETAILS_SCENARIO_2ND_MONTH,
  SERVICE_DETAILS_SCENARIO_3RD_MONTH,
} from "../core/string_table/ServiceDetailsStrings";
import { SERVICE_DETAILS_SCENARIO_1_TITLE } from "../core/string_table/ServiceDetailsStrings";

const getServiceDetailsString = (key) => getString(SERVICE_DETAILS, key);

const StyledBox = styled(Box)(({ theme }) => ({
  "& li": {
    paddingTop: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
  },
}));

export default function ServiceDetails() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#ebf9cf",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CloudusbMainLogo sx={{ width: "90%", maxWidth: 700 }} />
        </Box>
        <Container sx={{ maxWidth: "80%", pb: 2 }}>
          <Stack spacing={1}>
            <Button variant="contained" onClick={() => navigate("/home")}>
              {getServiceDetailsString(SERVICE_DETAILS_TO_HOME)}
            </Button>
            <Button variant="contained" onClick={() => navigate("/checkout")}>
              {getServiceDetailsString(SERVICE_DETAILS_REGISTER_PAYMENT_METHOD)}
            </Button>
          </Stack>
        </Container>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            backgroundColor: "#ebf9cf",
          }}
        >
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "lightblue" }}>
              <CardHeader
                title={getServiceDetailsString(SERVICE_DETAILS_1_TITLE)}
                subheader={getServiceDetailsString(
                  SERVICE_DETAILS_1_HEADER_TEXT
                )}
              />
              <CardContent sx={{ backgroundColor: "white" }}>
                <Stack direction="row" spacing={2}>
                  <CardMedia
                    component="img"
                    image="pay-per-use_500.png"
                    sx={{ width: "45%", height: "auto", maxWidth: 200 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(SERVICE_DETAILS_1_EXPLANATION)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "lightblue" }}>
              <CardHeader
                title={getServiceDetailsString(SERVICE_DETAILS_2_TITLE)}
                subheader={getServiceDetailsString(
                  SERVICE_DETAILS_2_HEADER_TEXT
                )}
              />
              <CardContent sx={{ backgroundColor: "white" }}>
                <Stack direction="row" spacing={2}>
                  <CardMedia
                    component="img"
                    image="base_charge_500.png"
                    sx={{ width: "45%", height: "auto", maxWidth: 200 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(SERVICE_DETAILS_2_EXPLANATION)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "lightblue" }}>
              <CardHeader
                title={getServiceDetailsString(SERVICE_DETAILS_3_TITLE)}
                subheader={getServiceDetailsString(
                  SERVICE_DETAILS_3_HEADER_TEXT
                )}
              />
              <CardContent sx={{ backgroundColor: "white" }}>
                <Stack direction="row" spacing={2}>
                  <CardMedia
                    component="img"
                    image="hot_cold.png"
                    sx={{ width: "45%", height: "auto", maxWidth: 200 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(SERVICE_DETAILS_3_EXPLANATION)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "lightblue" }}>
              <CardHeader
                title={getServiceDetailsString(SERVICE_DETAILS_4_TITLE)}
                subheader={getServiceDetailsString(
                  SERVICE_DETAILS_4_HEADER_TEXT
                )}
              />
              <CardContent sx={{ backgroundColor: "white" }}>
                <Stack direction="row" spacing={2}>
                  <CardMedia
                    component="img"
                    image="charging_storage_sizes.png"
                    sx={{ width: "45%", height: "auto", maxWidth: 200 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(SERVICE_DETAILS_4_EXPLANATION)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "lightblue" }}>
              <CardHeader
                title={getServiceDetailsString(SERVICE_DETAILS_5_TITLE)}
                subheader={getServiceDetailsString(
                  SERVICE_DETAILS_5_HEADER_TEXT
                )}
              />
              <CardContent sx={{ backgroundColor: "white" }}>
                <Stack direction="row" spacing={2}>
                  <CardMedia
                    component="img"
                    image="monthly_payment.png"
                    sx={{ width: "45%", height: "auto", maxWidth: 200 }}
                  />

                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(SERVICE_DETAILS_5_EXPLANATION)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ pb: 3 }}>
            <Card sx={{ backgroundColor: "lightblue" }}>
              <CardHeader
                title={getServiceDetailsString(SERVICE_DETAILS_SCENARIOS_TITLE)}
                subheader={getServiceDetailsString(
                  SERVICE_DETAILS_SCENARIOS_HEADER_TEXT
                )}
              />
              <CardContent sx={{ backgroundColor: "white" }}>
                <Box>
                  <Typography variant="h7" color="text.secondary">
                    <b>
                      {getServiceDetailsString(
                        SERVICE_DETAILS_SCENARIO_1_TITLE
                      )}
                    </b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(
                      SERVICE_DETAILS_SCENARIO_1_EXPLANATION
                    )}
                  </Typography>
                  <BaseTable
                    defaultAlign="center"
                    data={{
                      columns: [
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_1ST_MONTH
                          ),
                        },
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_2ND_MONTH
                          ),
                        },
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_3RD_MONTH
                          ),
                        },
                      ],
                      rows: [[2160, 1230, 1230]],
                    }}
                  />
                </Box>
                <Box sx={{ pt: 2 }}>
                  <Typography variant="h7" color="text.secondary">
                    <b>
                      {getServiceDetailsString(
                        SERVICE_DETAILS_SCENARIO_2_TITLE
                      )}
                    </b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(
                      SERVICE_DETAILS_SCENARIO_2_EXPLANATION
                    )}
                  </Typography>
                  <BaseTable
                    defaultAlign="center"
                    data={{
                      columns: [
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_1ST_MONTH
                          ),
                        },
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_2ND_MONTH
                          ),
                        },
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_3RD_MONTH
                          ),
                        },
                      ],
                      rows: [[670, 810, 950]],
                    }}
                  />
                </Box>
                <Box sx={{ pt: 2 }}>
                  <Typography variant="h7" color="text.secondary">
                    <b>
                      {getServiceDetailsString(
                        SERVICE_DETAILS_SCENARIO_3_TITLE
                      )}
                    </b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {getServiceDetailsString(
                      SERVICE_DETAILS_SCENARIO_3_EXPLANATION
                    )}
                  </Typography>
                  <BaseTable
                    defaultAlign="center"
                    data={{
                      columns: [
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_1ST_MONTH
                          ),
                        },
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_2ND_MONTH
                          ),
                        },
                        {
                          label: getServiceDetailsString(
                            SERVICE_DETAILS_SCENARIO_3RD_MONTH
                          ),
                        },
                      ],
                      rows: [[830, 0, 1420]],
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Container sx={{ maxWidth: "80%", pb: 2 }}>
          <Stack spacing={1}>
            <Button variant="contained" onClick={() => navigate("/home")}>
              {getServiceDetailsString(SERVICE_DETAILS_TO_HOME)}
            </Button>
            <Button variant="contained" onClick={() => navigate("/checkout")}>
              {getServiceDetailsString(SERVICE_DETAILS_REGISTER_PAYMENT_METHOD)}
            </Button>
          </Stack>
        </Container>
      </Container>
    </div>
  );
}
