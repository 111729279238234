import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import NorthIcon from "@mui/icons-material/North";
import FolderIcon from "@mui/icons-material/Folder";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import FileMenu from "./filetoolbar/FileMenu";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "./filetoolbar/Search";
import FileSortOptionMenu from "./filetoolbar/FileSortOptionMenu";
import {
  handleGoingUp,
  triggetFilesUpload,
  triggerFilesDownload,
} from "./handlers";
import { SEARCH_KEYWORD } from "../../core/GlobalStateKeys";
import {
  JAMSIL_FB_FOLDER_EXPLORER,
  JAMSIL_FB_PARENT_FOLDER,
  JAMSIL_FB_FILE_UPLOAD,
  JAMSIL_FB_FILE_DOWNLOAD,
  JAMSIL_FB_FILE,
  JAMSIL_FB_SORT_OPTION,
  JAMSIL_FB_SEARCH,
} from "../../core/string_table/JamsilFileBrowserStrings";
import getJamsilFbString from "./JamsilFbString";
import { useGS } from "usegs";

export default function FileToolBar({ minWidth, toggleFileNav = (e) => {} }) {
  const [searchKeyword, setSearchKeyword] = useGS(SEARCH_KEYWORD);
  return (
    <Box sx={{ overflowX: "auto", width: "100%" }}>
      <Toolbar variant="dense" sx={{ minWidth: minWidth, width: "100%" }}>
        <Tooltip title={getJamsilFbString(JAMSIL_FB_FOLDER_EXPLORER)}>
          <Stack direction="row">
            <AccountTreeIcon
              sx={{ color: "rgba(190,192,166,1)", marginTop: 0.8 }}
            />
            <Switch onChange={(e) => toggleFileNav(e.target.checked)} />
          </Stack>
        </Tooltip>
        <Tooltip title={getJamsilFbString(JAMSIL_FB_PARENT_FOLDER)}>
          <Button onClick={handleGoingUp}>
            <NorthIcon fontSize="string" />
            <FolderIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title={getJamsilFbString(JAMSIL_FB_FILE_UPLOAD)}>
          <Button onClick={triggetFilesUpload}>
            <UploadIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title={getJamsilFbString(JAMSIL_FB_FILE_DOWNLOAD)}>
          <Button onClick={(e) => triggerFilesDownload()}>
            <DownloadIcon fontSize="small" />
          </Button>
        </Tooltip>
        <FileMenu title={getJamsilFbString(JAMSIL_FB_FILE)} />
        <FileSortOptionMenu title={getJamsilFbString(JAMSIL_FB_SORT_OPTION)} />
        <Box sx={{ flexGrow: 1 }} />
        <Search sx={{ width: 150 }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={getJamsilFbString(JAMSIL_FB_SEARCH) + "…"}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </Search>
      </Toolbar>
    </Box>
  );
}
